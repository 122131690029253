
import GroupSelectionDetails, { GroupSelectionDetailsSignalR } from '@/entities/Participants/GroupSelectionDetails';
import { Component, Prop, Vue, Watch } from 'vue-facing-decorator';
import { VueDraggableNext } from 'vue-draggable-next';
import loading from '@/components/Common/loading.vue';
import ModalPopup from '@/components/Common/ModalPopup.vue';
import { ParticipantsGroupSelectionSignalRModel } from '@/signalRRequest/Participants/ParticipantsGroupSelectionSignalRModel';
import { SignalRModelRequest } from '@/signalRRequest/SignalRModelRequest';
import ModuleEnum from '@/enums/ModuleEnum';
import SubModuleEnum from '@/enums/SubModuleEnum';
import EmitEnum from '@/enums/EmitEnums';
import { StoreHelper } from '@/store/StoreHelper';
import { FetchData, Root, Store } from '@/main';
import { AppRequestInfo } from '@/entities/AppRequestInfo';
@Component({
  components: {
    draggable: VueDraggableNext,
    loading,
    ModalPopup,
  },
})
export default class GroupSelectionListView extends Vue {
  @Prop() groupSelectionQuestions!: GroupSelectionDetails[];
  @Prop() selectedGroupItem!: GroupSelectionDetails;
  private localGroupSelectionQuestions: GroupSelectionDetails[] = [];
  private groupSelectonIcon = require('@/assets/Images/group-placeholder-icon.svg');
  private dragDropIcon = require('@/assets/Images/drag-and-drop-icon.svg');
  private selectedItemBackgroundColor = '#F5F8FF';
  private defaultBackgroundColor = 'white';
  private selectedItem = GroupSelectionDetails.createEmpty();
  private selectedItemId = 0; // Selected group selection item id
  private showLoading = false;
  private loaderBorderColor = '';
  private modalPopupVisible = false;
  private showCloseButton = true;
  private modalPopupContent = '';
  private modalPopupShowCancelButton = true;
  private modalPopupCancelButtonText = '';
  private modalPopupConfirmButtonText = '';
  private showSectionModalPopup = '';
  private selectedGroupSelectionQuestionsItemSignalR: GroupSelectionDetailsSignalR = GroupSelectionDetailsSignalR.createEmpty();
  private hoverItemIndex: number | null = null;
  // constants
  private readonly Notification = 'Notification';
  private readonly NotificationUpdated = 'NotificationUpdated';
  private readonly NotificationSorting = 'NotificationSorting';

  private mounted() {
    this.localGroupSelectionQuestions = this.groupSelectionQuestions;
    // Unset the background color of list item to default color
    Root.on('unset-lisitng-background-color', ()=> {
      this.selectedItem = Object.assign({}, this.selectedGroupItem);
    });
    // Emit received from hub.ts on saving/ updating group selection question
    Root.on(
      'selected-group-selection-item-notification-completed',
      (notification: SignalRModelRequest) => {
        if (notification.IsSuccess) {
          this.PerformOperationOnList(notification);
        }
        const signalR: ParticipantsGroupSelectionSignalRModel = new ParticipantsGroupSelectionSignalRModel(
          Root,
          Store,
          this.$t,
        );
        signalR.GroupSelectionSignalRModelResponseReceived(notification);
      },
    );
    // Emit received from hub.ts on deleting a group selection question
    Root.on(
      'group-selection-item-deleted',
      (groupSelectionIds: number[]) => {
        groupSelectionIds.forEach((element) => {
          this.GroupSelectionQuestionItemDeletedOperation(element);
        });
      },
    );
    // Used to disable the list item on which the operation is performed until it is completed
    Root.on(
      EmitEnum.disableGroupSelectionItems,
      (selectedIds: number[]) => {
        selectedIds.forEach((element) => {
          this.localGroupSelectionQuestions.find(
            (x) => x.Id === element,
          )!.IsDisabled = true;
        });
      },
    );
    // Used to enabele the list item when performed operation is completed
    Root.on(
      EmitEnum.enableGroupSelectionItems,
      (selectedIds: number[]) => {
        selectedIds.forEach((element) => {
          this.localGroupSelectionQuestions.find(
            (x) => x.Id === element,
          )!.IsDisabled = false;
        });
      },
    );
  }
  private ShowDragAndDropIcon(val: null | number) {
    this.hoverItemIndex = val;
  }
  // Function invokes when hit receive from SignalR
  private PerformOperationOnList(notification: SignalRModelRequest) {
    if (
      notification.Module === ModuleEnum.ParticipantsGroupSelections &&
      notification.SubModule === SubModuleEnum.SingleDelete
    ) {
      if (notification.SelectedIds.length > 0) {
        if (
          this.selectedItem !== null && this.selectedItem !== undefined && this.selectedItem.Id === notification.SelectedIds[0]
        ) {
          this.CheckIfDeletedItemIsOpenInEditMode(notification);
        } else {
          this.GroupSelectionQuestionItemDeletedOperation(
            notification.SelectedIds[0],
          );
        }
      }
    } else if (
      notification.Module === ModuleEnum.ParticipantsGroupSelections &&
      notification.SubModule === SubModuleEnum.Sorting
    ) {
      if (notification.SelectedIds.length > 0) {
        if (this.selectedItem !== null && this.selectedItem !== undefined && this.selectedItem.Id > 0) {
          this.CheckIfSortingItemIsOpenInEditMode(notification);
        } else {
          const signalR: ParticipantsGroupSelectionSignalRModel = new ParticipantsGroupSelectionSignalRModel(
            Root,
            Store,
            this.$t,
          );
          if (!signalR.IsLoggedInUserIsMe(notification)) {
            Root.emit('reload-group-selection-data');
          }

        }
      }
    } else {
      this.GroupSelectionQuestionItemEditedOperationWithNotification(
        notification,
      );
    }
  }
  // Set item to the list of object received from signalR
  private GroupSelectionQuestionItemEditedOperationWithNotification(
    notification: SignalRModelRequest,
  ) {
    const groupSelectionItem: GroupSelectionDetailsSignalR = JSON.parse(
      notification.JSONData,
    );
    const objIndex = this.localGroupSelectionQuestions.findIndex(
      (obj: GroupSelectionDetails) => obj.Id === groupSelectionItem.Id,
    );
    const signalR: ParticipantsGroupSelectionSignalRModel = new ParticipantsGroupSelectionSignalRModel(
      Root,
      Store,
      this.$t,
    );
    // New Session Question Added
    if (objIndex === -1) {
      const pItem = signalR.FillGroupSelectionQuestionObjectFromSignalRResponseNewItem(
        groupSelectionItem,
      );
      this.localGroupSelectionQuestions.push(pItem);
      // scroll to bottom
      if (signalR.IsLoggedInUserIsMe(notification)) {
        this.RedirectToItem(this.localGroupSelectionQuestions.length - 1);
      }
    } else if (objIndex > -1) {
      if (
        this.selectedItem !== null && this.selectedItem !== undefined && this.selectedItem.Id === groupSelectionItem.Id
      ) {
        this.CheckIfUpdatedItemIsOpenInEditMode(
          notification,
          signalR,
          objIndex,
          groupSelectionItem,
        );
      } else {
        this.SetGroupSelectionQuestionItemInList(objIndex, signalR, groupSelectionItem);
      }

      // scroll to bottom
      if (signalR.IsLoggedInUserIsMe(notification)) {
        this.RedirectToItem(objIndex);
      }
    }
  }

  // To not to show popup for multi-admin
  private CheckIfUpdatedItemIsOpenInEditMode(
    notification: SignalRModelRequest,
    signalR: ParticipantsGroupSelectionSignalRModel,
    objIndex: number,
    gs: GroupSelectionDetailsSignalR,
  ) {
    if (signalR.IsLoggedInUserIsMe(notification)) {
      this.PerformSelectedItemUpdateOperation(
        this.SetGroupSelectionQuestionItemInList(objIndex, signalR, gs),
      );
    } else {
      this.modalPopupVisible = true;
      this.showCloseButton = false;
      this.showSectionModalPopup = this.NotificationUpdated;
      this.modalPopupShowCancelButton = true;
      this.modalPopupConfirmButtonText = this.$t('Button.Reload').toString();
      this.modalPopupCancelButtonText = this.$t('Button.Ignore').toString();

      this.modalPopupContent = this.$t('Message.ItemUpdatedBy', {
        username: notification.UserName,
      }).toString();
      this.selectedGroupSelectionQuestionsItemSignalR = gs;
    }
  }
  private PerformSelectedItemUpdateOperation(
    groupSelectonItem: GroupSelectionDetails,
  ) {
    this.selectedItem = groupSelectonItem;
    Root.emit('group-selection-question-updated', groupSelectonItem);
  }
  private CheckIfSortingItemIsOpenInEditMode(
    notification: SignalRModelRequest,
  ) {

    const signalR: ParticipantsGroupSelectionSignalRModel = new ParticipantsGroupSelectionSignalRModel(
      Root,
      Store,
      this.$t,
    );
    if (signalR.IsLoggedInUserIsMe(notification)) {
      Root.emit('reload-group-selection-data');
    } else {
      this.modalPopupVisible = true;
      this.showCloseButton = false;
      this.showSectionModalPopup = this.NotificationSorting;
      this.modalPopupShowCancelButton = false;
      this.modalPopupConfirmButtonText = this.$t('Button.Ok').toString();
      this.modalPopupCancelButtonText = this.$t('CancelButton').toString();
      if (this.selectedItem.Id > 0) {
        this.modalPopupContent = this.$t('Message.ItemUpdatedBy', {
          username: notification.UserName,
        }).toString();
      }
    }
  }

  // Setting item in list of group selection questions
  private SetGroupSelectionQuestionItemInList(
    objIndex: number,
    signalR: ParticipantsGroupSelectionSignalRModel,
    gs: GroupSelectionDetailsSignalR,
  ): GroupSelectionDetails {
    const groupSelectionItem = signalR.FillGroupSelectionQuestionObjectFromSignalRResponse(
      gs,
      objIndex,
      this.localGroupSelectionQuestions,
    );
    if(this.localGroupSelectionQuestions !== null && this.localGroupSelectionQuestions !== undefined) {
      this.localGroupSelectionQuestions[objIndex] = groupSelectionItem;
    }
    return groupSelectionItem;
  }

  // To scroll to created/ updated group selection question
  private RedirectToItem(index: number) {
    setTimeout(() => {
      const element = document.querySelectorAll(
        '[index="' + index + '"]',
      )[0];
      if (element) {
        element.scrollIntoView({
          block: 'end',
          behavior: 'smooth',
          inline: 'end',
        });
      }
    }, 100);
  }
  // Remove Group selection Question from Group selection Question Listing after it has been deleted
  private GroupSelectionQuestionItemDeletedOperation(gsId: number) {
    const objIndex = this.localGroupSelectionQuestions.findIndex(
      (obj: GroupSelectionDetails) => obj.Id === gsId,
    );
    if (objIndex > -1) {
      this.localGroupSelectionQuestions.splice(objIndex, 1);
    }
  }

  // To not to show popup for multi-admin
  private CheckIfDeletedItemIsOpenInEditMode(
    notification: SignalRModelRequest,
  ) {
    const signalR: ParticipantsGroupSelectionSignalRModel = new ParticipantsGroupSelectionSignalRModel(
      Root,
      Store,
      this.$t,
    );
    if (!signalR.IsLoggedInUserIsMe(notification)) {
      this.modalPopupVisible = true;
      this.showCloseButton = false;
      this.showSectionModalPopup = this.Notification;
      this.modalPopupShowCancelButton = false;
      this.modalPopupConfirmButtonText = this.$t('Button.Ok').toString();
      this.modalPopupCancelButtonText = this.$t('CancelButton').toString();

      this.modalPopupContent = this.$t('Message.ItemDeletedBy', {
        username: notification.UserName,
      }).toString();
      this.selectedItemId = notification.SelectedIds[0];
    } else {
      this.GroupSelectionQuestionItemDeletedOperation(
        notification.SelectedIds[0],
      );
    }
  }

  // When no item available create new link for create new group selection item
  private CreateNewGroupSelectionItem() {
    this.$emit('create-new-item');
  }
  // To highlight the selected item
  private SetBackgroundColor(id: number) {
    if(this.selectedItem !== null && this.selectedItem !== undefined && this.selectedItem.Id !== 0) {
      return this.selectedItem.Id === id ? this.selectedItemBackgroundColor : this.defaultBackgroundColor;
    }
    return this.defaultBackgroundColor;
  }
  private DraggingStart() {
    // code to perform any action on drag start
  }
  // Draggable component on drag end event
  private DraggingEnd() {
    // code to perform any operation on drag end
    this.SaveGroupSelectionSortOrder();
  }
  // Save group selection questions sort order
  private SaveGroupSelectionSortOrder() {
    const gsIds: number[] = [];
    this.localGroupSelectionQuestions.forEach((element) => {
      gsIds.push(element.Id);
    });
    // call signalr
    const signalObject = this.SendNotificationRequest();
    this.showLoading=true;
    FetchData('/GroupSelections/SetOrder', JSON.stringify({
      idsInOrders: gsIds.toString(),
    }))
      .then(() => {
        this.UpdateSorting();
        this.$emit('update-sorting-group-selection-listing',{
          filteredGroupSelectionListing: this.localGroupSelectionQuestions,
          signalObject,
        });
        this.showLoading=false;
      })
      .catch((error) => {
        window.console.log(error);
        this.showLoading=false;
      });
  }
  private SendNotificationRequest(): SignalRModelRequest {
    setTimeout(() => {
      Root.emit('show-notification-popup', true);
      Root.emit('show-notification-loading', true);
    }, 100);
    const signalR: ParticipantsGroupSelectionSignalRModel = new ParticipantsGroupSelectionSignalRModel(
      Root,
      Store,
      this.$t,
    );
    let signalObject: SignalRModelRequest = new SignalRModelRequest();
    signalObject = signalR.SortingGroupSelectionQuestionsSignalRModelRequest;
    const arr: number[] = [];
    this.localGroupSelectionQuestions.forEach((item)=> {
      arr.push(item.Id);
      signalObject.SelectedIds = arr;
    });

    signalObject.Description = this.$t(signalObject.SubmitMessage, {
      user: signalR.GetUserName(
        signalObject,
        this.$t('UserName.You').toString(),
      ),
    }).toString();
    const storeHelper: StoreHelper = new StoreHelper(Store);
    const reqInfo: AppRequestInfo = storeHelper.GetRequestInfoFromStore();
    signalObject.ApplicationId = Number(reqInfo.ApplicationId);
    signalObject.EventId = Number(reqInfo.ApplicationInstanceId);
    signalObject.JSONData = JSON.stringify(this.localGroupSelectionQuestions);
    return signalObject;
  }
  // Used to update list item sorting
  private UpdateSorting() {
    this.localGroupSelectionQuestions.forEach((element, index) => {
      element.SortOrder = index;
    });
  }
  // Select clicked item
  private SelectGroupSelectionItem(data: GroupSelectionDetails) {
    this.selectedItem = data;
    this.$emit('change-selected-item', data);
  }
  private ConfirmClick(val: boolean) {
    this.modalPopupVisible = false;
    if(val) {
      if(this.showSectionModalPopup === this.Notification) {
        this.$emit('group-selection-cancel-operation');
      } else if(this.showSectionModalPopup === this.NotificationSorting) {
        Root.emit('reload-group-selection-data');
        this.$emit('group-selection-cancel-operation');
      } else {
        Root.emit('reload-group-selection-data');
      }
    }
  }
  // To set value of selectedItem on any change in SelectedGroupItem
  @Watch('selectedGroupItem')
  private CheckChangeInSelectedGroupItem(val: GroupSelectionDetails) {
    this.selectedItem = val;
  }
  // To set value of localGroupSelectionQuestions on any change in GroupSelectionQuestions
  @Watch('groupSelectionQuestions')
  private CheckChangeIngroupSelectionQuestions(val: GroupSelectionDetails[]) {
    this.localGroupSelectionQuestions = val;
  }
}
