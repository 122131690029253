import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, vModelText as _vModelText, normalizeClass as _normalizeClass, withDirectives as _withDirectives, vModelCheckbox as _vModelCheckbox, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-24f1f2d1"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "editPane scroll-top default-page-height" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "right-container-padding" }
const _hoisted_4 = { class: "inline-flex100 align-items-center" }
const _hoisted_5 = { class: "main-title-width" }
const _hoisted_6 = { class: "main-heading primary-heading" }
const _hoisted_7 = {
  key: 0,
  class: "inline-flex"
}
const _hoisted_8 = ["src"]
const _hoisted_9 = {
  key: 0,
  class: "margin-top-15"
}
const _hoisted_10 = {
  key: 0,
  class: "information-message-div"
}
const _hoisted_11 = { class: "margin-top-0" }
const _hoisted_12 = ["disabled", "maxlength"]
const _hoisted_13 = { class: "margin-top-20" }
const _hoisted_14 = { class: "no-margin description" }
const _hoisted_15 = ["disabled"]
const _hoisted_16 = { class: "margin-top-15" }
const _hoisted_17 = { class: "no-margin description" }
const _hoisted_18 = ["disabled"]
const _hoisted_19 = {
  key: 0,
  class: "group-selection-question-deadline"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CreateGroup = _resolveComponent("CreateGroup")!
  const _component_VueModalPopUp = _resolveComponent("VueModalPopUp")!
  const _component_ValidationSummary = _resolveComponent("ValidationSummary")!
  const _component_WarningMessageBar = _resolveComponent("WarningMessageBar")!
  const _component_Datepicker = _resolveComponent("Datepicker")!
  const _component_GroupSelectionResponseOptions = _resolveComponent("GroupSelectionResponseOptions")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.showCreateNewGroup)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_CreateGroup, {
            groups: _ctx.groups,
            onDisplayGroupCreation: _cache[0] || (_cache[0] = ($event: any) => (_ctx.DisplayCreateNewGroup($event))),
            onNewCreatedGroup: _cache[1] || (_cache[1] = ($event: any) => (_ctx.AssignNewCreatedGroup($event)))
          }, null, 8, ["groups"])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", null, [
      (_ctx.showVueModalPopUp)
        ? (_openBlock(), _createBlock(_component_VueModalPopUp, {
            key: 0,
            "modal-pop-up": _ctx.unsavedModalPopUp,
            "is-content-left-aligned": true,
            onClose: _ctx.CloseModelPopup,
            onSave: _cache[2] || (_cache[2] = ($event: any) => (_ctx.DeleteModelPopUp($event)))
          }, null, 8, ["modal-pop-up", "onClose"]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", null, [
          _createElementVNode("button", {
            id: "btnCancelGroupSelectionQuestion",
            class: "btn btn-secondary",
            onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.Cancel()))
          }, _toDisplayString(_ctx.localSelectedItem.IsEditable ? _ctx.$t('CancelButton') : _ctx.$t('Button.Close')), 1)
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.$t('Participants.GroupSelection')), 1)
        ]),
        (_ctx.localSelectedItem.IsEditable)
          ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
              _createElementVNode("button", {
                id: "btnSaveGroupSelectionQuestion",
                class: "btn btn-primary",
                onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.Save(_ctx.v$)))
              }, _toDisplayString(_ctx.$t('SaveButton')), 1),
              (_ctx.localSelectedItem.Id !== 0)
                ? (_openBlock(), _createElementBlock("button", {
                    key: 0,
                    id: "btnDeleteGroupSelectionQuestion",
                    class: "btn btn-delete",
                    onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.DeleteGroupSelectionItem()))
                  }, [
                    _createElementVNode("img", { src: _ctx.deleteIcon }, null, 8, _hoisted_8)
                  ]))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true)
      ]),
      (_ctx.isValidationSummaryVisible)
        ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
            _createVNode(_component_ValidationSummary, {
              "error-fields": _ctx.validationErrorFields,
              onCloseClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.CloseValidationSummary($event)))
            }, null, 8, ["error-fields"])
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", {
        class: _normalizeClass([_ctx.localSelectedItem.IsEditable ? 'margin-top-35': 'top-margin-in-fields'])
      }, [
        (!_ctx.localSelectedItem.IsEditable)
          ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
              _createVNode(_component_WarningMessageBar, { "information-view-model": _ctx.informationMessage }, null, 8, ["information-view-model"])
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", null, [
          _createElementVNode("label", _hoisted_11, _toDisplayString(_ctx.$t('Title')), 1),
          _withDirectives(_createElementVNode("input", {
            "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.v$.localSelectedItem.Title.$model) = $event)),
            disabled: !_ctx.localSelectedItem.IsEditable,
            class: _normalizeClass([[_ctx.localSelectedItem.IsEditable ? _ctx.status(_ctx.v$.localSelectedItem.Title) : ''], "form-control"]),
            type: "text",
            maxlength: _ctx.maxGroupSelectionTitleLength
          }, null, 10, _hoisted_12), [
            [_vModelText, _ctx.v$.localSelectedItem.Title.$model]
          ])
        ]),
        _createElementVNode("div", _hoisted_13, [
          _createElementVNode("label", _hoisted_14, [
            _withDirectives(_createElementVNode("input", {
              "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.localSelectedItem.IsRequired) = $event)),
              disabled: !_ctx.localSelectedItem.IsEditable,
              type: "checkbox",
              class: "checkbox-alignment"
            }, null, 8, _hoisted_15), [
              [_vModelCheckbox, _ctx.localSelectedItem.IsRequired]
            ]),
            _createTextVNode(_toDisplayString(_ctx.$t('Participants.GroupSelection.MarkAsMandatory')), 1)
          ])
        ]),
        _createElementVNode("div", _hoisted_16, [
          _createElementVNode("label", _hoisted_17, [
            _withDirectives(_createElementVNode("input", {
              ref: "chkHasDeadline",
              "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.localSelectedItem.HasDeadline) = $event)),
              disabled: !_ctx.localSelectedItem.IsEditable,
              type: "checkbox",
              class: "checkbox-alignment",
              onClick: _cache[10] || (_cache[10] = ($event: any) => (_ctx.DoesGroupSelectionQuestionHasDeadline()))
            }, null, 8, _hoisted_18), [
              [_vModelCheckbox, _ctx.localSelectedItem.HasDeadline]
            ]),
            _createTextVNode(_toDisplayString(_ctx.$t('Participants.GroupSelection.SetDeadlineForAttendee')), 1)
          ]),
          (_ctx.localSelectedItem.HasDeadline)
            ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
                _createVNode(_component_Datepicker, {
                  id: "groupSelectionAvailableTillDate",
                  date: _ctx.GetGroupSelectionQuestionDeadline(),
                  "full-day-time-format": _ctx.CheckTimeFormat(),
                  mindatetime: _ctx.minDateTime,
                  "id-value": _ctx.$t('Participants.GroupSelection'),
                  disable: !_ctx.localSelectedItem.IsEditable,
                  onSelectedDate: _ctx.SetAvailableTillDate,
                  onSelectedUTCDate: _ctx.SetAvailableTillDateUTC
                }, null, 8, ["date", "full-day-time-format", "mindatetime", "id-value", "disable", "onSelectedDate", "onSelectedUTCDate"])
              ]))
            : _createCommentVNode("", true),
          _createVNode(_component_GroupSelectionResponseOptions, {
            "response-options": _ctx.localSelectedItem.GroupSelectionOptions,
            groups: _ctx.groups,
            "is-disabled": !_ctx.localSelectedItem.IsEditable,
            onCreateUpdateResponseOptionData: _cache[11] || (_cache[11] = ($event: any) => (_ctx.SetGroupSelectionResponseOptionsData($event)))
          }, null, 8, ["response-options", "groups", "is-disabled"])
        ])
      ], 2)
    ])
  ]))
}