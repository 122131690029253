import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "landing-page" }
const _hoisted_2 = { class: "right-container-padding" }
const _hoisted_3 = { class: "main-heading-parent" }
const _hoisted_4 = { class: "primary-heading font-size-20" }
const _hoisted_5 = { class: "sub-heading margin-top-30" }
const _hoisted_6 = { class: "description" }
const _hoisted_7 = { class: "no-margin description" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t("Participants.GroupSelection.LandingPageHeading")), 1)
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("label", _hoisted_6, _toDisplayString(_ctx.$t("Participants.GroupSelection.LandingPageSubHeadingLine1")), 1),
        _createElementVNode("label", _hoisted_7, _toDisplayString(_ctx.$t("Participants.GroupSelection.LandingPageSubHeadingLine2")), 1)
      ])
    ])
  ]))
}