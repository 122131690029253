import GroupSelectionOptionViewModel from './GroupSelectionOptionViewModel';
import { BaseSignalRModelRequestJSON } from '@/signalRRequest/SignalRModelRequest';
export default class GroupSelectionDetails {
  public static createEmpty(): GroupSelectionDetails {
    return new GroupSelectionDetails();
  }
  constructor(
    public Id: number = 0,
    public EventId: number = 0,
    public Title: string = '',
    public IsRequired: boolean = true,
    public HasDeadline: boolean = true,
    public AvailableTillUTC: Date | null = new Date(),
    public SortOrder: number = 0,
    public IsEditable: boolean = true,
    public GroupSelectionOptions: GroupSelectionOptionViewModel[] = [],
    public IsDisabled: boolean = false,
  ) {}
  public static CompareGroupSelectionObjects({ val1, val2 }: { val1: GroupSelectionDetails; val2: GroupSelectionDetails }): boolean {
    const GroupSelectionItem1 = GroupSelectionDetails.SetGroupSelectionObject(val1);
    const GroupSelectionItem2 = GroupSelectionDetails.SetGroupSelectionObject(val2);
    if (JSON.stringify(GroupSelectionItem1) === JSON.stringify(GroupSelectionItem2)) {
      return true;
    } else {
      return false;
    }
  }
  public static SetGroupSelectionObject(val: GroupSelectionDetails) {
    return {
      Id: val.Id,
      EventId: val.EventId,
      Title: val.Title,
      IsRequired: val.IsRequired,
      HasDeadline: val.HasDeadline,
      AvailableTillUTC: val.AvailableTillUTC,
      SortOrder: val.SortOrder,
      IsEditable: val.IsEditable,
      GroupSelectionOptions: val.GroupSelectionOptions,
    };
  }
  public static GetGroupSelectionQuestionItemFromGroupSelectionSignalR(groupSelectionItem: GroupSelectionDetails, groupSelectionQuestion: GroupSelectionDetailsSignalR) {
    groupSelectionItem.Id = groupSelectionQuestion.Id;
    groupSelectionItem.EventId = groupSelectionQuestion.EventId;
    groupSelectionItem.Title = groupSelectionQuestion.Title;
    groupSelectionItem.IsRequired = groupSelectionQuestion.IsRequired;
    groupSelectionItem.HasDeadline = groupSelectionQuestion.HasDeadline;
    groupSelectionItem.AvailableTillUTC = groupSelectionQuestion.AvailableTillUTC;
    groupSelectionItem.SortOrder = groupSelectionQuestion.SortOrder;
    groupSelectionItem.IsEditable = groupSelectionQuestion.IsEditable;
    groupSelectionItem.GroupSelectionOptions = groupSelectionQuestion.GroupSelectionOptions;
    return groupSelectionItem;
  }
}
export class GroupSelectionDetailsSignalR extends BaseSignalRModelRequestJSON {
  public static createEmpty(): GroupSelectionDetailsSignalR {
    return new GroupSelectionDetailsSignalR();
  }
  constructor(
    public Id: number = 0,
    public EventId: number = 0,
    public Title: string = '',
    public IsRequired: boolean = true,
    public HasDeadline: boolean = true,
    public AvailableTillUTC: Date | null = new Date(),
    public SortOrder: number = 0,
    public IsEditable: boolean = true,
    public GroupSelectionOptions: GroupSelectionOptionViewModel[] = [],
  ) {
    super(0, '');
  }
  public static GetGroupSelectionQuestionItemSignalR(question: GroupSelectionDetails): GroupSelectionDetailsSignalR {
    const item: GroupSelectionDetailsSignalR = GroupSelectionDetailsSignalR.createEmpty();
    item.Id = question.Id;
    item.EventId = question.EventId;
    item.Title = question.Title;
    item.IsRequired = question.IsRequired;
    item.HasDeadline = question.HasDeadline;
    item.AvailableTillUTC = question.AvailableTillUTC;
    item.SortOrder = question.SortOrder;
    item.IsEditable = question.IsEditable;
    item.GroupSelectionOptions = question.GroupSelectionOptions;
    item.selectedName = question.Title;
    return item;
  }
}
